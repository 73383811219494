<template>
  <div style="margin: 24px 0">
    <!--a-input placeholder="输入试试看" v-model="inputValue" allowClear  :autoSize="{ minRows: 3, maxRows: 5 }" ></a-input>
      <div>{{inputValue}}</div-->
    <div class="title">
      <h1>低代码控制脚本编辑器</h1>
    </div>

    <!--a-textarea
      v-model="inputValue"
      placeholder="请输入脚本"
      :style="style"
      :auto-size="{ minRows: 1, maxRows: 1 }"
    /-->
    <MonacoEditor
      width="100%"
      height="477"
      :code="code"
      :key="randomkey"
      :editorOptions="options"
      @mounted="onMounted"
      @codeChange="onCodeChange"
    >
    </MonacoEditor>
    <a-button @click="() => saveCurrent()" type="primary">在线缓存</a-button>
    &nbsp;
    <a-button @click="() => compile()" type="primary">编译当前</a-button>
    &nbsp;
    <a-button @click="() => run()" type="primary">运行</a-button>
    &nbsp;
    <a-button @click="() => reboot()" type="primary">重启引擎</a-button>
    &nbsp;
    <a-button @click="() => currentJs()" type="primary">获取缓存</a-button>
    &nbsp;
    <a-button type="primary">仿真</a-button>

    <!--div v-highlight>
      <pre>
    <code>
      {{ inputValue }}
    </code>
  </pre>
    </div-->
    <div>{{ inputValue2}}</div>
  </div>
</template>

    <script>
import { EventSourcePolyfill } from "event-source-polyfill";
import axios from "axios";
import MonacoEditor from "vue-monaco-editor";
export default {
  components: {
    MonacoEditor,
  },
  data() {
    return {
      inputValue2: "",
      es: null,
      style: { margin: "20px,20px" },
      editor: null,
      code: "//以下为脚本模板，您可以基于该模板进行开发。语法兼容js,编程细节请阅读cwl博客。 \nfunction dealControl(){\n\n\n}",
      options: {
        selectOnLineNumbers: false,
      },
      randomkey: 1231231,
      res:""
    };
  },
  created() {
    this.createSse();
  },
  destroyed() {
    this.es.close();
  },
  methods: {
    onMounted(editor) {
      this.editor = editor;
    },
    onCodeChange(editor) {
      //console.log(this.editor.getValue());
    },
    // createRamdomKey随机生成值，其值类似于id。该方法最为重要，在给code赋值之后，调用这个方法
    createRamdomKey() {
      return Math.floor(Math.random() * (10, 1000000012313));
    },
    getDetail() {
      // res为请求返回的值
      this.$nextTick(() => {
        this.code = this.res;
        this.randomkey = this.createRamdomKey();
      });
    },
    run() {
      axios({
        method: "get",
        url: "https://api.rslly.top/api/v2/JsRun",
        // url:this_1.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      }).then((result) => {
        if (result.data.errorCode === 200) {
          this.$message.success("运行成功");
        } else if (result.data.code === 401) {
          this.$message.error("Unauthorized");
        } else if (result.data.code === 403) {
          this.$message.error("Forbidden");
        } else if (result.data.code === 404) {
          this.$message.error("Not Found");
        } else if (result.data.message === 44 || result.data.message === 40) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/Login");
        }
      });
    },
    saveCurrent() {
      console.log(this.editor.getValue())
      axios({
        method: "post",
        url: "https://api.rslly.top/api/v2/postJs",
        // url:this_1.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
          "Content-Type": "text/plain",
        },
        data: this.editor.getValue(),
      }).then((result) => {
        if (result.data.errorCode === 200) {
          this.$message.success("提交成功");
        } else if (result.data.code === 401) {
          this.$message.error("Unauthorized");
        } else if (result.data.code === 403) {
          this.$message.error("Forbidden");
        } else if (result.data.code === 404) {
          this.$message.error("Not Found");
        } else if (result.data.message === 44 || result.data.message === 40) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/Login");
        }
      });
    },
    compile(){
      axios({
        method: "get",
        url: "https://api.rslly.top/api/v2/JsPreRunCurrent",
        // url:this_1.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      }).then((result) => {
        console.log(result)
        if (result.data.errorCode === 200) {
          this.$message.success("编译当前成功");
        } else if (result.data.code === 401) {
          this.$message.error("Unauthorized");
        } else if (result.data.code === 403) {
          this.$message.error("Forbidden");
        } else if (result.data.code === 404) {
          this.$message.error("Not Found");
        } else if (result.data.message === 44 || result.data.message === 40) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/Login");
        }
      });
    },
    reboot(){
      axios({
        method: "get",
        url: "https://api.rslly.top/api/v2/rebootJs",
        // url:this_1.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      }).then((result) => {
        if (result.data.errorCode === 200) {
          this.$message.success("重启成功");
        } else if (result.data.code === 401) {
          this.$message.error("Unauthorized");
        } else if (result.data.code === 403) {
          this.$message.error("Forbidden");
        } else if (result.data.code === 404) {
          this.$message.error("Not Found");
        } else if (result.data.message === 44 || result.data.message === 40) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/Login");
        }
      });
    },
    currentJs(){
      let that =this;
      axios({
        method: "get",
        url: "https://api.rslly.top/api/v2/currentJs",
        // url:this_1.mypath,
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      }).then((result) => {
        
        that.res=result.data.data;
        //console.log(that.res);
        that.getDetail();
        if (result.data.errorCode === 200) {
          this.$message.success("获取缓存成功");
        } else if (result.data.code === 401) {
          this.$message.error("Unauthorized");
        } else if (result.data.code === 403) {
          this.$message.error("Forbidden");
        } else if (result.data.code === 404) {
          this.$message.error("Not Found");
        } else if (result.data.message === 44 || result.data.message === 40) {
          this_1.$message.error("权限认证失效，请重新登录");
          this_1.$router.push("/Login");
        }
      });
    },
    getValue() {
      alert("您刚才输入的是：" + this.inputValue);
    },
    createSse() {
      let that = this;
      // console.log("执行成功")
      let url = `https://api.rslly.top/sse/connect/123`;
      this.es = new EventSourcePolyfill(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
        heartbeatTimeout: 1000000000,
      });

      this.es.onopen = function (event) {
        //console.log("eiwuew")
        console.log("连接成功", event);
      };

      this.es.onmessage = function (event) {
        // to to something…
        console.log("接收信息", event.data);
        that.inputValue2 = event.data;
        that.$message.success("脚本执行成功");
      };

      this.es.onerror = function (error) {
        // 监听错误
        console.log("错误", error);
      };
    },
  },
};
</script>
<style >
.title {
  text-align: center;
}
</style>